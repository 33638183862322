import React, { Component } from 'react';
import firebase from './firebase.js';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = { files: [] };

    const db = firebase.firestore();
    db.collection("files").orderBy("timestamp", "desc").onSnapshot((querySnapshot) => {
      const posts = querySnapshot.docs.map(doc => Object.assign({ doc }, doc.data()));
      this.setState({ files: posts });
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const file = e.target.file.files[0];
    const fileName = file.name;
    const fileRefPath = `files/${fileName}`;

    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(fileRefPath);

    this.setState({ isUploading: true });
    fileRef.put(file).then((snapshot) => {
      const fileUrl = snapshot.task.snapshot.downloadURL;
      const file = {
        timestamp: new Date(),
        fileName: fileName,
        fileRefPath: fileRefPath,
        fileUrl: fileUrl
      }
      const db = firebase.firestore();
      db.collection('files').add(file)
        .then((docRef) => {
          this.setState({ isUploading: false });
          console.log("Document written with ID: ", docRef.id);
        })
        .catch((error) => {
          this.setState({ isUploading: false });
          console.error("Error adding document: ", error);
        });
    });
  }

  deleteFile(file) {
    const storageRef = firebase.storage().ref();
    var fileRef = storageRef.child(file.fileRefPath);

    fileRef.delete().then(() => {
      console.log('Deleted image', file.fileRefPath);
    }).catch((error) => {
      console.error('Failed deleting image', file.fileRefPath, error);
    });

    file.doc.ref.delete().then(() => {
      console.log('Successfully deleted file:', file.fileName);
    }).catch((error) => {
      console.error('Failed deleting file:', file.fileName, error);
    });
  }

  render() {
    if (!this.props.user) {
      return null;
    }

    const files = this.state.files.map((file) => {
      return <div className="file"
                  key={file.timestamp}>
        <strong className="photo__title">
          <a href={file.fileUrl}>
            {file.fileName}
          </a>
          {this.props.user &&
            <button className="button--link"
                    onClick={() => this.deleteFile(file)}>
              Delete
            </button>
          }
        </strong>
      </div>
    })

    return (
      <div>
        {this.props.user &&
          <form className="image-uploader"
                onSubmit={this.handleSubmit}>
            <input className="image-uploader__input"
                   type="file"
                   name="file" />
            <button className="button"
                    type="submit"
                    disabled={this.state.isUploading}>
              {this.state.isUploading ? "Adding File..." : "Add File"}
            </button>
          </form>
        }

        {files}
      </div>
    )
  }
}

export default Admin;
