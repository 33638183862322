import React, { Component } from 'react';
import firebase from './firebase.js';
import ImageUploader from './ImageUploader';

class Photo extends Component {
  constructor(props) {
    super(props);
    this.state = { isEditing: false };
    this.delete = this.delete.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
  }

  formatTitle() {
    // Replace * with italics
    return this.props.post.title.replace(/\*([^*]*)\*/g, '<em>$1</em>');
  }

  delete() {
    const post = this.props.post;
    const storageRef = firebase.storage().ref();
    var imageRef = storageRef.child(post.imageRefPath);

    imageRef.delete().then(() => {
      console.log('Deleted image', post.imageRefPath);
    }).catch((error) => {
      console.error('Failed deleting image', post.imageRefPath, error);
    });

    post.doc.ref.delete().then(() => {
      console.log('Successfully deleted post:', post.title);
    }).catch((error) => {
      console.error('Failed deleting post:', post.title, error);
    });
  }

  toggleEdit() {
    this.setState({ isEditing: !this.state.isEditing });
  }

  onLoad(e) {
    e.target.parentElement.classList.remove('photo--loading');
  }

  render() {
    const post = this.props.post;

    return <div className="photo photo--loading">
      <img src={post.imageUrl}
           alt={post.title}
           className="photo__image"
           onLoad={this.onLoad} />
      <div className="photo__info">
        <span className="photo__title"
              dangerouslySetInnerHTML={{__html: this.formatTitle()}} />
        {this.props.user &&
          <span className="photo__actions">
            <button className="button--link"
                    onClick={this.toggleEdit}>
              {this.state.isEditing ? "Cancel Edit" : "Edit"}
            </button>
            <button className="button--link"
                    onClick={this.delete}>
              Delete
            </button>
          </span>
        }
      </div>

      {this.state.isEditing &&
        <ImageUploader post={post}
                       posts={this.props.posts}
                       user={this.props.user}
                       onEditComplete={() => this.toggleEdit()} />
      }
    </div>
  }
}

export default Photo;
