const firebase = require('firebase/app');
require('firebase/auth');
require('firebase/storage');
require('firebase/firestore');

const config = {
  apiKey: "AIzaSyDb0wa55TcdDntP9qEK1ScEuKPK9u9xuWo",
  authDomain: "joelfisherphoto.firebaseapp.com",
  databaseURL: "https://joelfisherphoto.firebaseio.com",
  projectId: "joelfisherphoto",
  storageBucket: "joelfisherphoto.appspot.com",
  messagingSenderId: "892373643977"
};

const stagingConfig = {
  apiKey: "AIzaSyAPgpkZ63H6ts7WRkoabBbok6u60NY8ePQ",
  authDomain: "joel-fisher-photo-staging.firebaseapp.com",
  databaseURL: "https://joel-fisher-photo-staging.firebaseio.com",
  projectId: "joel-fisher-photo-staging",
  storageBucket: "joel-fisher-photo-staging.appspot.com",
  messagingSenderId: "354683814271"
};

firebase.initializeApp(window.location.hostname === 'localhost' ? stagingConfig : config);

export default firebase;
