import React, { Component } from 'react';
import firebase from './firebase.js';
import ImageUploader from './ImageUploader';
import Photo from './Photo';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { posts: [] };

    const db = firebase.firestore();
    db.collection("posts").orderBy("timestamp", "desc").onSnapshot((querySnapshot) => {
      const posts = querySnapshot.docs.map(doc => {
        return { ...(doc.data()), doc }
      });
      this.setState({ posts });
    });
  }

  render() {
    const photos = this.state.posts.map((post) => {
      return <Photo post={post}
                    posts={this.state.posts}
                    user={this.props.user}
                    key={post.timestamp} />
    })

    return (
      <div>
        <ImageUploader user={this.props.user}
                       posts={this.state.posts} />
        {photos}
      </div>
    );
  }
}

export default Home;
