import React, { Component } from 'react';
import firebase from './firebase.js';

class Header extends Component {
  logoutUser() {
    firebase.auth().signOut()
  }

  render() {
    return (
      <header>
        <h1>Joel W. Fisher</h1>

        <span className="header__links">
          <a className="header__link"
             href="mailto:joelwfisher@gmail.com">
            Inquiries
          </a>
        </span>

        {this.props.user &&
          <span className="header__user-actions">
            <a className="header__link" href="/">Home</a>
            <a className="header__link" href="/dropbox">Dropbox</a>
            <button className="button--link"
                    onClick={this.logoutUser}>
              Log Out
            </button>
          </span>
        }
      </header>
    )
  }
}

export default Header;
