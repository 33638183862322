import React, { Component } from 'react';
import firebase from './firebase.js';

const ADD_TO_TOP_OPTION_LABEL = "Add to top";

class ImageUploader extends Component {
  constructor(props) {
    super(props);
    this.state = { isUploading: false };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  uploadFile(file) {
    const imageName = file.name;
    const imageRefPath = `images/${imageName}`;
    const storageRef = firebase.storage().ref();
    const existingPost = this.props.post;

    if (existingPost && existingPost.imageRefPath) {
      // Delete existing image before replacing
      const existingImageRef = storageRef.child(existingPost.imageRefPath);
      existingImageRef.delete();
    }

    return storageRef.child(imageRefPath).put(file);
  }

  savePost(title, position, imageUploadSnapshot) {
    debugger;
    const existingPost = this.props.post;
    const postRef = existingPost ? existingPost.doc.ref : firebase.firestore().collection('posts').doc();
    const customPositionSelected = position !== ADD_TO_TOP_OPTION_LABEL;
    let positionTimestamp;
    let duplicateTimestampPost;
    let updates = { title };

    if (customPositionSelected) {
      positionTimestamp = this.incrementTimestamp(position);
      duplicateTimestampPost = this.props.posts.find(post => {
        return post.timestamp.toString() === positionTimestamp.toString();
      });
    }

    if (existingPost && positionTimestamp) {
      // For existing posts, only set timestamp if custom position selected
      updates.timestamp = positionTimestamp;
    } else {
      // Default timestamp to current time for new posts if not custom
      updates.timestamp = positionTimestamp || new Date();
    }

    if (imageUploadSnapshot) {
      updates.imageRefPath = imageUploadSnapshot.metadata.fullPath;
      imageUploadSnapshot.ref.getDownloadURL().then(downloadURL => {
        updates.imageUrl = downloadURL;
        this.saveUpdates(duplicateTimestampPost, postRef, updates);
      });
    } else {
      this.saveUpdates(duplicateTimestampPost, postRef, updates);
    }
  }

  saveUpdates(duplicateTimestampPost, postRef, updates) {
    if (duplicateTimestampPost) {
      return this.updatePostAndDuplicate(duplicateTimestampPost, postRef, updates);
    } else {
      return postRef.set(updates, { merge: true });
    }
  }

  // Save both post and the post that needs to be shifted due to duplicate
  // custom timestamp. Running in a transaction prevents the list from
  // rererending underneath us.
  updatePostAndDuplicate(duplicateTimestampPost, postRef, updates) {
    return firebase.firestore().runTransaction((transaction) => {
      // This `get` is really just here because transaction callbacks
      // are expected to return one /shrug
      return transaction.get(postRef).then(() => {
        // Bump any posts that where previously given this custom position
        transaction.update(duplicateTimestampPost.doc.ref, {
          timestamp: this.incrementTimestamp(duplicateTimestampPost.timestamp)
        });
        transaction.set(postRef, updates, { merge: true });
      })
    });
  }

  incrementTimestamp(position) {
    let positionDate = typeof position === 'string' ?  new Date(position) : position;
    positionDate.setSeconds(positionDate.getSeconds() + 1);
    return positionDate;
  }

  handleSubmit(e) {
    e.preventDefault();
    const file = e.target.image.files[0];
    const title = e.target.title.value;
    const position = e.target.position.value;
    let savePromise;

    this.setState({ isUploading: true });

    if (file) {
      savePromise = this.uploadFile(file).then((imageUploadSnapshot) => {
        return this.savePost(title, position, imageUploadSnapshot);
      });
    } else {
      savePromise = this.savePost(title, position);
    }

    savePromise.then(() => {
      if (this._isMounted) {
        this.setState({ isUploading: false });
        this.props.onEditComplete && this.props.onEditComplete();
      }
    })
    .catch((error) => {
      if (this._isMounted) {
        this.setState({ isUploading: false });
      }
    });
  }

  render() {
    if (!this.props.user) {
      return null
    }

    let buttonLabel;
    if (this.props.post) {
      buttonLabel = this.state.isUploading ? "Saving..." : "Save Edits";
    } else {
      buttonLabel = this.state.isUploading ? "Adding Image..." : "Add Image";
    }

    const options = this.props.posts.map((post) => {
      return <option value={post.timestamp}
                     key={post.timestamp}>
        {post.title}
      </option>
    })

    return (
      <form className="image-uploader"
            onSubmit={this.handleSubmit}>
        <input className="image-uploader__input"
               type="file"
               name="image" />
        <input className="image-uploader__input"
               type="text"
               name="title"
               defaultValue={this.props.post && this.props.post.title}
               placeholder="Image Title" />
        <small className="image-uploader__title-hint">
          Wrap with *asterisks* for <em>italics</em>
        </small>
        <select className="image-uploader__input"
                name="position">
          <option>{ADD_TO_TOP_OPTION_LABEL}</option>
          <optgroup label="Position above...">
            {options}
          </optgroup>
        </select>
        <button className="button"
                type="submit"
                disabled={this.state.isUploading}>
          {buttonLabel}
        </button>
      </form>
    );
  }
}

export default ImageUploader;
