import React, { Component } from 'react';
import firebase from './firebase.js';
import './App.css';
import Header from './Header';
import LoginForm from './LoginForm';
import Dropbox from './Dropbox';
import Home from './Home';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { user: null };

    firebase.auth().onAuthStateChanged(user => {
      this.setState({ user });
      console.log('user changed to', user && user.email);
    });
  }

  render() {
    let contentComponent = null;

    if (window.location.pathname.match(/^\/login\/*$/) !== null) {
      contentComponent = <LoginForm user={this.state.user} />
    } else if (window.location.pathname.match(/^\/dropbox\/*$/) !== null) {
      contentComponent = <Dropbox user={this.state.user} />
    } else {
      contentComponent = <Home user={this.state.user} />
    }

    return (
      <div className="content">
        <Header user={this.state.user} />
        {contentComponent}
      </div>
    )
  }
}

export default App;
