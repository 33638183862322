import React, { Component } from 'react';
import firebase from './firebase.js';

class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = { shouldShowResetPasswordForm: false };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showResetPassWordForm = this.showResetPassWordForm.bind(this);
    this.sendResetPasswordEmail = this.sendResetPasswordEmail.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    firebase.auth().signInWithEmailAndPassword(e.target.email.value, e.target.password.value);
  }

  showResetPassWordForm() {
    this.setState({ shouldShowResetPasswordForm: true });
  }

  sendResetPasswordEmail(e) {
    e.preventDefault();
    const resetEmail = e.target.email.value;
    firebase.auth().sendPasswordResetEmail(resetEmail).then(function() {
      alert(`Check your ${resetEmail} email for reset instructions.`);
    }).catch(function(error) {
      alert('There was an error sending the password reset email. Try again?');
    });
  }

  render() {
    if (this.props.user) {
      window.location.pathname = "/";
      return null;
    }

    if (this.state.shouldShowResetPasswordForm) {
      return (
        <form className="login-form" onSubmit={this.sendResetPasswordEmail}>
          <input type="text" name="email" placeholder="Email" />
          <button className="button">
            Send Reset Password Email
          </button>
        </form>
      )
    }

    return (
      <div>
        <form className="login-form" onSubmit={this.handleSubmit}>
          <input type="text" name="email" placeholder="Email" />
          <input type="password" name="password" placeholder="Password" />
          <button className="button">
            Submit
          </button>
        </form>

        <div>
          Forgot it?
          <button className="button--link"
                  onClick={this.showResetPassWordForm}>
            Reset Password
          </button>
        </div>
      </div>
    );
  }
}

export default LoginForm;
